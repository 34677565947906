<template>
  <div>
    <header class="bg-trama-header">
      <div class="container-fluid d-flex justify-content-between">
        <div class="grid-logo">
          <b-navbar-brand to="/" class="logotipo" @click="closeMenu"> <h2>El Otro Madrid</h2> </b-navbar-brand>
          <img src="/img/turismo-responsable/sierra-norte-turismo-responsable.svg" alt="" />
        </div>
        <div>
          <div class="opennmenu" @click="openMenu">
            <div class="lineamenu"></div>
            <div class="lineamenu"></div>
            <div class="lineamenu"></div>
          </div>
        </div>

        <div :class="seleccionClases">
          <div class="dismiss" @click="closeMenu">
            <div class="lineamenu x-1"></div>
            <div class="lineamenu x-2"></div>
          </div>
          <div class="container mx-5">
            <div class="row d-flex flex-column enlaces-menu">
              <h3 class="text-primary mb-4">Descubre todos nuestros planes</h3>
              <div class="mb-3" @click="closeMenu"><nuxt-link to="/rutas/ruta-parejas">Para parejas</nuxt-link></div>
              <div class="mb-3" @click="closeMenu">
                <nuxt-link to="/rutas/ruta-familias" @click="closeMenu">Para familias</nuxt-link>
              </div>
              <div class="mb-3" @click="closeMenu">
                <nuxt-link to="/rutas/ruta-naturaleza" @click="closeMenu">Para amantes de la naturaleza</nuxt-link>
              </div>
              <div class="mb-3" @click="closeMenu">
                <nuxt-link to="/rutas/ruta-aventureros" @click="closeMenu">Para aventureros</nuxt-link>
              </div>
              <div class="mb-3" @click="closeMenu">
                <nuxt-link to="/rutas/ruta-foodies" @click="closeMenu">Para foodies</nuxt-link>
              </div>
              <div class="mb-3" @click="closeMenu">
                <nuxt-link to="/rutas/ruta-influencers" @click="closeMenu">Para influencers</nuxt-link>
              </div>
              <div class="mb-3" @click="closeMenu">
                <nuxt-link to="/rutas/ruta-culturetas" @click="closeMenu">Para culturetas</nuxt-link>
              </div>
              <div class="mb-3" @click="closeMenu">
                <nuxt-link to="/rutas/ruta-todos" @click="closeMenu">Para todos</nuxt-link>
              </div>
              <div class="mb-3" @click="closeMenu">
                <nuxt-link to="/rutas/ruta-accesible" @click="closeMenu">Accesible</nuxt-link>
              </div>
            </div>
          </div>

          <div class="py-5 d-flex justify-content-center">
            <div class="d-flex justify-content-center iconos-menu">
              <a
                href="https://www.facebook.com/SierraNorteMadridTurismo.oficial/?ref=settings"
                target="_blank"
                class="btn btn-link"
              >
                <fa :icon="['fab', 'facebook-f']" />
              </a>
              <a href="https://www.instagram.com/sierranortedemadrid/" target="_blank" class="btn btn-link">
                <fa :icon="['fab', 'instagram']" />
              </a>
              <a href="https://twitter.com/sierranortemad" target="_blank" class="btn btn-link">
                <fa :icon="['fab', 'twitter']" />
              </a>
              <a href="tel:+34630430128" target="_blank" class="btn btn-link">
                <fa :icon="['fa', 'phone']" />
              </a>
              <a href="mailto:info@sierranortemadrid.org" target="_blank" class="btn btn-link">
                <fa :icon="['fa', 'envelope']" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
    <main @click="closeMenu">
      <nuxt />

      <pre-footer />
      <no-ssr>
        <cookie-law theme="dark-lime" button-text="Aceptar">
          <template v-slot:message>
            Utilizamos cookies para hacer que la navegación por la web sea más sencilla. Puedes saber más sobre las
            cookies que usamos en nuestra
            <a href="https://www.sierranortemadrid.org/politica-de-cookies/" target="_blank">política de cookies.</a>
          </template>
        </cookie-law>
      </no-ssr>
    </main>
    <footer class="bg-white mb-0 d-flex align-items-end" @click="closeMenu">
      <div class="container d-flex-row text-center py-5">
        <div class="container">
          <div class="row d-flex justify-content-center">
            <div class="col-sm-8">
              <h3 class="mb-3 text-primary">¿Quieres saber más?</h3>
              <form name="contact-form" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
                <div class="form-group">
                  <input
                    v-model="form.email"
                    type="email"
                    name="email"
                    class="form-control"
                    :class="{ 'is-invalid': emailError && formSent }"
                    placeholder="Tu correo electrónico (campo obligatorio)"
                    required
                  />
                  <div v-show="emailError && formSent" class="invalid-feedback">
                    Por favor, escribe un correo de contacto válido.
                  </div>
                </div>
                <div class="form-group">
                  <textarea
                    v-model="form.message"
                    name="mensaje"
                    class="form-control"
                    :class="{ 'is-invalid': messageError && formSent }"
                    placeholder="¿Que necesitas saber? (campo obligatorio)"
                    required
                  >
                  </textarea>
                  <div v-show="messageError && formSent" class="invalid-feedback">
                    Por favor, escribe un mensaje.
                  </div>
                </div>
                <div class="form-check form-group">
                  <input
                    id="aceptarAvisoLegal"
                    v-model="form.avisoLegal"
                    type="checkbox"
                    class="form-check-input"
                    :class="{ 'is-invalid': avisoLegalError && formSent }"
                  />
                  <label class="form-check-label" for="aceptarAvisoLegal"
                    >Confirmo haber leído y aceptado el
                    <a href="https://www.sierranortemadrid.org/aviso-legal/" target="_blank">aviso legal</a> y la
                    <a href="https://www.sierranortemadrid.org/politica-de-privacidad/">
                      política de privacidad.
                    </a>
                  </label>
                </div>

                <div class="form-check form-group">
                  <input
                    id="aceptarPublicidad"
                    v-model="form.avisoPublicidad"
                    type="checkbox"
                    class="form-check-input"
                    :class="{ 'is-invalid': avisoPublicidadError && formSent }"
                  />
                  <label class="form-check-label" for="aceptarPublicidad">
                    Confirmo haber <b-link v-b-modal.modalPublicidad variant="secondary">leído y aceptado</b-link> el
                    envío de información de carácter comunicativo o publicitario.
                  </label>
                </div>

                <button type="button" class="btn btn-primary btn-lg mb-5" :disabled="formSuccess" @click="sendForm">
                  Enviar
                </button>
              </form>
              <div v-show="formSuccess" class="alert alert-success" style="margin-top: -22px;">
                Se ha enviado el formulario correctamente
              </div>
              <div v-show="formError" class="alert alert-danger" style="margin-top: -22px;">
                Ocurrió un error al enviar el formulario
              </div>
            </div>
          </div>
        </div>
        <p class="text-center">
          <a href="https://www.facebook.com/SierraNorteMadridTurismo.oficial/?ref=settings" target="_blank">
            <fa :icon="['fab', 'facebook-f']" class="mr-2" />
          </a>
          <a href="https://www.instagram.com/sierranortedemadrid/" target="_blank">
            <fa :icon="['fab', 'instagram']" class="mr-2" />
          </a>
          <a href="https://twitter.com/sierranortemad" target="_blank">
            <fa :icon="['fab', 'twitter']" />
          </a>
        </p>
        <p>
          <a href="mailto:info@sierranortemadrid.org"> info@sierranortemadrid.org</a>
        </p>
        <p><a href="tel:+34912778101">630 430 128</a></p>
        <div class="d-flex justify-content-around py-2 flex-wrap flex-sm-nowrap img-footer">
          <img src="/img/logos/logo-footer-galsinma.png" class="px-2 mb-4 mb-sm-0" width="auto" />
          <img src="/img/logos/logo-footer-comunidad-madrid.png" class="px-2 mb-4 mb-sm-0" width="auto" />
          <img src="/img/logos/logo-footer-ministerio.png" class="px-2 mb-4 mb-sm-0" width="auto" />
          <img src="/img/logos/logo-footer-leader.png" class="px-2 mb-4 mb-sm-0" width="auto" />
          <img src="/img/logos/logo-footer-feader.png" class="px-2 mb-4 mb-sm-0" width="auto" />
          <img src="/img/logos/logo-footer-sierra-norte.png" class="px-2 mb-4 mb-sm-0" width="auto" />
        </div>
      </div>
    </footer>
    <b-modal
      id="modalPublicidad"
      centered
      scrollable
      size="lg"
      hide-footer
      title="Información sobre envío de información de carácter publicitaria"
    >
      <div class="container">
        <div class="row">
          <p>
            <strong>Mancomunidad de Servicios del Valle Norte del Lozoya,</strong> con C.I.F. número P2800074C,
            domicilio en Plaza Picaso, 1 – 28730 Buitrago del Lozoya (Madrid), teléfono
            <a href="tel:+34918680056">918680056</a> y correo electrónico
            <a href="mailto:vallenortelozoya@hotmail.com">vallenortelozoya@hotmail.com</a>, en base al consentimiento
            prestado, trata la información que nos facilita con el fin de enviarle nuestro boletín informativo y
            ofrecerle información comercial, promociones y sorteos organizados, y demás comunicaciones con el fin de
            promocionar los recursos turísticos de la Sierra Norte. Los datos proporcionados se conservarán mientras se
            mantenga la relación con la Mancomunidad o durante los años necesarios para cumplir con las obligaciones
            legales. Los datos no se cederán a terceros salvo en los casos en que exista una obligación legal o usted
            nos preste su consentimiento. Usted tiene derecho a obtener confirmación sobre si en la
            <strong>Mancomunidad de Servicios del Valle Norte del Lozoya</strong> estamos tratando sus datos personales,
            rectificar los datos inexactos o solicitar su supresión cuando los datos ya no sean necesarios.
          </p>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import isEmail from 'validator/lib/isEmail'
import CookieLaw from 'vue-cookie-law'

export default {
  components: {
    PreFooter: () => import('../components/PreFooter'),
    CookieLaw
  },

  data() {
    return {
      form: {
        email: null,
        message: null,
        avisoLegal: false,
        avisoPublicidad: false
      },
      seleccionClases: {
        sidebar: true,
        active: false
      },
      formSuccess: false,
      formError: false,
      formSent: false
    }
  },

  computed: {
    emailError() {
      return !this.form.email || !isEmail(this.form.email)
    },
    messageError() {
      return !this.form.message
    },
    avisoLegalError() {
      return !this.form.avisoLegal
    },
    avisoPublicidadError() {
      return !this.form.avisoPublicidad
    }
  },

  methods: {
    openMenu() {
      this.seleccionClases.active = true
    },
    closeMenu() {
      this.seleccionClases.active = false
    },
    async sendForm(e) {
      e.preventDefault()
      this.formSent = true

      if (this.emailError || this.messageError) return

      const res = await fetch('/', {
        method: 'POST',
        body: [
          `form-name=contact-form`,
          `email=${encodeURIComponent(this.form.email)}`,
          `message=${encodeURIComponent(this.form.message)}`
        ].join('&'),
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      })

      if (res.ok) {
        this.formSuccess = true
      } else {
        this.formError = true
      }
    }
  }
}
</script>
<style scoped lang="scss">
.grid-logo {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr;
  img {
    max-width: 100px;
    margin: 5px auto;
  }
}
@media only screen and (max-width: 1500px) {
  .grid-logo {
    grid-template-columns: 1fr 1fr;
    align-items: none;
    h2 {
      font-size: 25px;
    }
    img {
      max-width: 45px;
      padding: 10px 2px;
      margin: 5px auto 5px 0;
    }
  }
}
</style>
