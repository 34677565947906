import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1c60bd12 = () => interopDefault(import('../pages/bases-legales-mi-otro-madrid.vue' /* webpackChunkName: "pages/bases-legales-mi-otro-madrid" */))
const _3d2a001a = () => interopDefault(import('../pages/rutas/ruta-accesible.vue' /* webpackChunkName: "pages/rutas/ruta-accesible" */))
const _142bb01d = () => interopDefault(import('../pages/rutas/ruta-aventureros.vue' /* webpackChunkName: "pages/rutas/ruta-aventureros" */))
const _2b5484a2 = () => interopDefault(import('../pages/rutas/ruta-culturetas.vue' /* webpackChunkName: "pages/rutas/ruta-culturetas" */))
const _5f0d5c86 = () => interopDefault(import('../pages/rutas/ruta-familias.vue' /* webpackChunkName: "pages/rutas/ruta-familias" */))
const _b528d75c = () => interopDefault(import('../pages/rutas/ruta-foodies.vue' /* webpackChunkName: "pages/rutas/ruta-foodies" */))
const _381f838f = () => interopDefault(import('../pages/rutas/ruta-influencers.vue' /* webpackChunkName: "pages/rutas/ruta-influencers" */))
const _0101527a = () => interopDefault(import('../pages/rutas/ruta-naturaleza.vue' /* webpackChunkName: "pages/rutas/ruta-naturaleza" */))
const _6d17b2d1 = () => interopDefault(import('../pages/rutas/ruta-parejas.vue' /* webpackChunkName: "pages/rutas/ruta-parejas" */))
const _63c8e1c6 = () => interopDefault(import('../pages/rutas/ruta-todos.vue' /* webpackChunkName: "pages/rutas/ruta-todos" */))
const _0547af50 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/bases-legales-mi-otro-madrid",
    component: _1c60bd12,
    name: "bases-legales-mi-otro-madrid"
  }, {
    path: "/rutas/ruta-accesible",
    component: _3d2a001a,
    name: "rutas-ruta-accesible"
  }, {
    path: "/rutas/ruta-aventureros",
    component: _142bb01d,
    name: "rutas-ruta-aventureros"
  }, {
    path: "/rutas/ruta-culturetas",
    component: _2b5484a2,
    name: "rutas-ruta-culturetas"
  }, {
    path: "/rutas/ruta-familias",
    component: _5f0d5c86,
    name: "rutas-ruta-familias"
  }, {
    path: "/rutas/ruta-foodies",
    component: _b528d75c,
    name: "rutas-ruta-foodies"
  }, {
    path: "/rutas/ruta-influencers",
    component: _381f838f,
    name: "rutas-ruta-influencers"
  }, {
    path: "/rutas/ruta-naturaleza",
    component: _0101527a,
    name: "rutas-ruta-naturaleza"
  }, {
    path: "/rutas/ruta-parejas",
    component: _6d17b2d1,
    name: "rutas-ruta-parejas"
  }, {
    path: "/rutas/ruta-todos",
    component: _63c8e1c6,
    name: "rutas-ruta-todos"
  }, {
    path: "/",
    component: _0547af50,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
